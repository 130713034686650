.undoSection {
  position: fixed;
  z-index: 5;
  background: #006fff;
  color: white;
  bottom: -50px;
  right: 50px;
  box-shadow: 0px 0px 10px #9e9e9e;
  padding: 10px;
  font-size: 13px;
  border-radius: 5px;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  display: flex;
}

.showSection {
  bottom: 50px;
}

.align {
  display: flex;
}

.undo {
  padding-left: 5px;
  text-align: center;
  margin-top: 4px;
  font-weight: bold;
}

.undoIcon {
  fill: white;
}
