@import url("https://unpkg.com/treeflex@2.0.1/dist/css/treeflex.css");

$whiteSmoke: #b4b4b4;
$darkSlateGray: #343842;
$royalBlue: #3e88df;
$olive: #936c00;
$chocolate: #cb5004;
$oliveDrab: #508416;
$brown: #8c1d40;
$ifthenColor: #ff258a;
$datacollectorColor: #ff6f00;

.tf-tree {
  overflow: visible !important;
  width: fit-content;
  height: fit-content;
}

.tf-tree li li:before {
  border-color: $whiteSmoke !important;
}

.tf-tree .tf-nc:after,
.tf-tree .tf-nc:before,
.tf-tree .tf-node-content:after,
.tf-tree .tf-node-content:before {
  border-color: $whiteSmoke !important;
}

.tf-tree .tf-nc,
.tf-tree .tf-node-content {
  border: none !important;
  padding: 0px !important;
}

.tf-tree li {
  padding: 0 0.1em !important;
}

.tf-tree li ul {
  margin: 0 !important;
  margin-top: 2em !important;
}

.addItem {
  border: 1px solid $whiteSmoke;
  color: $whiteSmoke;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
}

.collapsedBtn {
  background: $darkSlateGray;
  border-color: $darkSlateGray;
  color: white;
}

.itemHolder {
  border: 1px solid $whiteSmoke;
  border-radius: 14px;
  width: 200px;
  text-align: left;
  box-shadow: 0px 0px 10px #dedede;
}

.matchBoxBase {
  width: 125px;
  color: white;
  background: black;
  border-radius: 13px;
  font-size: 14px;
  border: 1px solid black;
  padding: 6px 9px !important;
}

.matchBox20 {
  background: $oliveDrab;
  border-color: $oliveDrab;
}

.ifthenMatch {
  background: $ifthenColor;
  border-color: $ifthenColor;
}

.datacollectorMatch {
  background: $datacollectorColor;
  border-color: $datacollectorColor;
}

.matchLine {
  line-height: 15px;
}

.noMatch {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.matchSubtext {
  font-size: 10px;
}

.matchHeader {
  text-transform: uppercase;
  font-weight: bold;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nodeHeaderHolder {
  display: flex;
  border-bottom: 1px solid $whiteSmoke;
}

.typeHolder {
  width: fit-content;
  padding: 2px 6px !important;
  color: white;
  display: flex;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bolder;
}

.deleteBtn {
  width: 15px;
  height: 15px;
  border: 1px solid $whiteSmoke;
  color: $whiteSmoke;
  border-radius: 10px;
  font-size: 11px;
  position: relative;
  text-align: center;
}

.nodeTopRight {
  flex: 1;
}

.nodeTopLeft {
  flex: 10;
}

.nodeText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  height: 43px;
  font-size: 12px;
  line-height: 17px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.nodeRowPadding {
  padding: 5px 8px !important;
}

.start {
  background: $darkSlateGray;
  color: white;
  padding: 8px 16px !important;
  border-radius: 10px;
  font-size: 14px;
  text-transform: uppercase;
}

.collapseHolder {
  height: 10px;
  width: 10px;
  margin-top: -3px !important;
  margin-left: 1px !important;
  transform-origin: bottom;
}

.typeIcon {
  width: 13px;
  margin-right: 5px !important;
  margin-top: 2px !important;
}

.typeText {
  font-size: 12px;
  margin-top: 1px !important;
}

.textLabelColor {
  background-color: $royalBlue;
}

.datacollectorLabelColor {
  background-color: $datacollectorColor;
}

.promptLabelColor {
  background-color: $olive;
}

.jumpLabelColor {
  background-color: $chocolate;
}

.branchLabelColor {
  background-color: $oliveDrab;
}

.triggerLabelColor {
  background-color: $brown;
}

.ifthen-hasvalueLabelColor,
.ifthen-valueLabelColor,
.ifthen-rangeLabelColor,
.ifthen-range-numberLabelColor,
.ifthen-range-letterLabelColor,
.ifthen-existsnotLabelColor {
  background-color: $ifthenColor;
}

.deleteSvg {
  height: 10px;
  position: absolute;
  left: 3px;
  top: 1px;
}

.singleLineEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
