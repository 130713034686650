.dg-export-data {
  /*padding-top: 0.6rem!important;
    padding-bottom: 0.6rem!important;*/
  display: flex;
  cursor: pointer;
  transition: background-color 250ms;

  &:hover {
    background-color: #ededed;
  }

  &:active {
    background-color: #dedede;
  }
}

.MuiAlert-icon {
  padding: 7px 0 !important;
}

.MuiAlert-message {
  padding: 8px 0 !important;
}

.MuiAlert-action {
  padding-left: 16px !important;
}

.MuiAlert-root {
  padding-left: 6px 16px !important;
}

.export-data__left {
  height: 50px;
  line-height: 50px;
  padding-top: 0.5rem !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  svg {
    fill: rgb(135, 52, 80);
  }
}

.export-data__right {
  height: 50px;
  line-height: 50px;
  padding-left: 0.5rem !important;
}

.dg-export-data__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000;

  div {
    padding: unset;
  }

  .react-datepicker__current-month {
    padding-top: 0.4em;
  }
}

.dg-export-data__modal-content {
  position: relative;
  background: rgb(255, 255, 255);
  margin: 100px auto;
  width: 35em;
  height: 80%;
  max-height: 53em;
  padding: 0 !important;
  overflow-y: auto;
}

.ed-modal__title {
  font-size: 1.5em;
  font-weight: 500;
  padding-top: 1.5em !important;
  padding-left: 1.5em !important;
  padding-bottom: 1.5em !important;
}

.ed-modal__close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 2.5em !important;
  padding-top: 2.5em !important;
  cursor: pointer;

  &:hover {
    /*svg {
        fill: gray;
      }*/
    .close-button--circle {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &:active {
    .close-button--circle {
      width: 50px;
      height: 50px;
      transform: translateX(5px) translateY(-5px);
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

.close-button--circle {
  position: absolute;
  top: 1.9em;
  right: 1.9em;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: transparent;
  transition: background-color 250ms, transform 250ms ease-out,
    width 250ms ease-out, height 250ms ease-out;
}

.ed-modal__gray-bar {
  width: 100%;
  height: 1px;
  background-color: rgb(213, 213, 213);
}

.ed-modal__section-title {
  position: relative;
  font-size: 1.1em;
  padding-top: 1.3rem !important;
  padding-left: 2rem !important;
  font-weight: 500;
}

.ed-modal__section-title[data-css="overwrite"] {
  padding-top: 0 !important;
}

.ed-modal__section-sub-title {
  padding-top: 0.1rem !important;
  padding-left: 2rem !important;
}

.ed-modal__toggle {
  position: absolute;
  top: 1em;
  right: 2em;
}

.ed-modal__filter-labels {
  display: flex;
  margin-top: 2.5em;
  padding-left: 2rem !important;
  font-size: 0.8em;
}

.ed-modal__filter-labels[data-css="overwrite"] {
  display: flex;
  margin-top: 2.5em;
  padding-left: 2.3rem !important;
  font-size: 0.8em;
}

.ed-modal__label {
  width: 10.6em;
}

.ed-modal__input {
  & > div {
    width: 7.5em;
    font-size: 1.4em;
  }
}

.ed-modal__filter-inputs {
  display: flex;
  margin-top: 0;
  padding-left: 2rem !important;
  font-size: 0.8em;
}

.ed-modal__select {
  width: 75%;
  padding-left: 2rem !important;
  padding-right: 2.5rem !important;

  div[class*="ValueContainer"] {
    padding: 2px 8px;
  }

  div[class*="Control"] {
    padding: 0.5rem;
  }

  div[class*="Menu"] {
    div {
      padding: 1em;
    }
  }
}

.ed-modal__radio-group {
  display: flex;
  margin-top: 0;
  padding-left: 2rem !important;
  font-size: 0.8em;
}

.ed-modal__radio-group[data-css="overwrite"] {
  margin-top: 1em;
}

/*.ed-modal__radio {

  }*/

.ed-modal__radio-text {
  line-height: 3.2em;
  font-size: 1.2em;
  cursor: pointer;
}

.ed-modal__footer {
  padding-top: 3em !important;
  padding-bottom: 3em !important;
}
