.CreateAlert {
  border: 1px solid white;
  background-color: gold;
  color: black;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
}

.SectionHeader {
  margin-top: 1.1rem;
  margin-left: 0.86rem;
  padding-right: 1rem;
}

.TablePage {
  margin-top: -1.6rem;
  margin-right: -2.6rem;
  margin-left: -1.6rem;
  border: 1px solid white;
}
