.headerNumbers {
  display: flex;
  flex-wrap: wrap;
}

.numberItem {
  flex: 1;
}

.container {
  padding: 2rem 2.5rem;
}

.title {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.number {
  font-size: 36px;
  text-align: center;
  font-weight: bold;
}

.subtitle {
  font-size: 11px;
  text-align: center;
  margin-top: -5px;
  color: gray;
}
