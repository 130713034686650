.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.modal {
  width: 40%;
  z-index: 100;
  background: white;
  position: absolute;
  /* margin: 5.75rem auto; */
  top: 80px;
  border-radius: 4px;
  padding: 1rem;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.modal-header {
  display: flex;
  justify-content: flex-end;
}
.modal-close-button {
  padding: 0px;
  margin: 0px;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  background-color: #ffffff00;
  cursor: pointer;
  border: none;
}

.modal-close-button:focus {
  outline: 0;
  opacity: 0.3;
}
