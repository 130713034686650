.NextPage {
  border: solid lightgrey;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 0.3em;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 0.6rem;
  height: 0.6rem;
}

.NextPage__wrapper {
  width: 2em;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.NextPage__wrapper:hover {
  background-color: rgb(237, 237, 237);
}

.NextPage--prev {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-left: 0.3em;
}
