* {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  --border-color-grey: rgb(221, 221, 221);
}

p {
  display: inline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-datepicker__input-container {
  width: unset !important;
}

.MuiDrawer-paper {
  position: fixed !important;
}

.MuiListItemIcon-root {
  margin-left: 7px;
}

.MuiPaper-elevation3 {
  box-shadow: 0px 0px 5px #8a8a8a !important;
}

.clickable:hover {
  cursor: pointer;
}

.hoverButton:hover {
  font-weight: 900;
  cursor: pointer;
}

.hoverButton {
  font-weight: 400;
}

.hoverBackground:hover {
  background-color: #ededed;
}
