.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableStatus {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
