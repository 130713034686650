.input {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  box-sizing: border-box;
}

.input--textAnswer {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: flex;
  flex: 1 1 0%;
  color: rgb(59, 66, 82);
  border: 1px solid rgb(141, 141, 141);
  padding: 0.5rem 1rem;
  width: 100%;
}

.input:focus {
  outline: none !important;
  box-shadow: 0 0 4px #7caee9;
}

.textarea {
  padding: 1rem;
  border-radius: 4px;
  box-sizing: border-box;
}

.textarea:focus {
  outline: none !important;
  box-shadow: 0 0 4px #7caee9;
}

.select {
}

.selectControl {
  cursor: pointer;
  border-color: #8d8d8d;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.selectControlOpen {
  cursor: pointer;
  border-color: #8d8d8d;
  border-radius: 4px 4px 0px 0px;
  -webkit-border-radius: 4px 4px 0px 0px;
  -moz-border-radius: 4px 4px 0px 0px;
  -ms-border-radius: 4px 4px 0px 0px;
  -o-border-radius: 4px 4px 0px 0px;
}

.selectPlaceholder {
}

.selectMenu {
  border-color: #8d8d8d;
  border-radius: 0px 0px 4px 4px;
  -webkit-border-radius: 0px 0px 4px 4px;
  -moz-border-radius: 0px 0px 4px 4px;
  -ms-border-radius: 0px 0px 4px 4px;
  -o-border-radius: 0px 0px 4px 4px;
}

.selectArrow {
}

.statusBase {
  width: fit-content;
  padding: 2px 6px;
  border: 1px solid #006fff;
  color: #006fff;
  border-radius: 3px;
  /* color: white; */
  font-size: 13px;
  /* margin-top: 5px; */
}

.qsuccess {
  /* background: green; */

  border: 1px solid #78ab63;
  color: #78ab63;
}

.qerror {
  /* background: red; */

  border: 1px solid #e01037;
  color: #e01037;
}

.qWhiteOut {
  border: 1px solid #fff;
  color: #fff;
}

.disabled {
  cursor: not-allowed;
  background: #dedede;
}

.link-modal__input {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  flex: 1 1 0%;
  color: rgb(59, 66, 82);
  border: 1px solid rgb(141, 141, 141);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  box-sizing: border-box;
  width: 90%;
}

.link-modal__button {
  border: 2px solid rgb(110, 110, 110);
  border-radius: 30px;
  padding: 0.8em;
  width: 7em;
  margin-left: 0.5em;
  background-color: #fff;
  font-weight: 700;
  color: rgb(110, 110, 110);
  font-size: 1.1em;
  cursor: pointer;
  transition: background 110ms ease-out, color 110ms ease-out,
    border 110ms ease-out;
}

.link-modal__button:hover {
  background-color: rgb(210, 210, 210);
  color: #000;
}

.link-modal__button--yellow {
  background-color: rgb(255, 198, 39);
  width: 5em;
  margin-left: 0em;
  color: #000;
  border: 2px solid rgb(255, 198, 39);
}

.link-modal__button--yellow:hover {
  background-color: rgb(255, 221, 128);
  border: 2px solid rgb(255, 221, 128);
  color: rgb(110, 110, 110);
}

.link-modal__error {
  color: white;
  background: crimson;
  padding: 1em;
  margin-bottom: 1em;
  width: 90%;
}

/* .select-css {
  display: block;
  font-size: 16px;
  font-family: Roboto;
  color: #3b4252;
  padding: 0.5rem 1rem;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  border: 1px solid #8d8d8d;
  border-radius: 4px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url(assets/drop-down-arrow.svg);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.select-css::-ms-expand {
  display: none;
}

.select-css:hover {
  cursor: pointer;
}

.select-css:focus {
  box-shadow: 0 0 4px #7caee9;
  box-shadow: 0 0 4px -moz-mac-focusring;
  outline: none;
}

.select-css option {
  font-weight: normal;
}

*/
