.MuiAccordion-root.Mui-expanded:last-child {
  margin-bottom: 10px !important;
}

.MuiAccordion-root.Mui-expanded:first-child {
  margin-top: 10px !important;
}

/*.MuiAccordionDetails-root
{
    background-color: rgb(250, 250, 250);
}*/

.MuiButtonBase-root:hover {
  background-color: rgb(250, 250, 250);
}

.MuiAccordionSummary-content {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
