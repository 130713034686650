.publishBox {
  margin-left: 10px;
}

.versionControl {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
  display: flex;
  flex-direction: column;
}

.selectBox {
  width: 230px;
}

.submitError {
  background: #ff004b;
  padding: 10px;
  color: white;
  font-weight: bold;
}

.FE-modal-header {
  text-transform: uppercase;
  font-weight: bold;
}

.FE-modal-subheader {
  margin-bottom: 3.5em;
}

.FE-modal-option-bar__wrapper {
  display: inline-block;
  width: 100%;
  overflow-x: auto;
}

.FE-modal-option-bar {
  display: flex;
}

.FE-modal-option-bar--left {
  justify-content: space-between;
}

.FE-modal-option-bar--right {
  justify-content: flex-end;
}

.FE-modal-option-bar__item {
  white-space: pre;
}

.FE-modal-option-bar-item__label {
  margin-right: 1em;
  font-weight: bold;
  height: 3em;
  line-height: 3em;
  font-weight: bold;
  display: inline-block;
  min-width: 5em;
}

.FE-modal-option-bar-item__input {
  display: inline-block;
}

.FE-modal-option-bar-item__input .selectContainer {
  width: 11em;
}

.FE-modal-table {
  height: calc(100% - 13rem);
  width: 100%;
  overflow-y: auto;
  margin-top: 2em;
}

.FE-modal-table > div > div {
  padding: 0 !important;
}

.FE-modal-sort {
  display: block;
  position: absolute;
  top: 4rem;
  right: 2.6rem;
}

.FE-export-button__wrapper {
  display: block;
  position: absolute;
  top: 4.25rem;
  right: 18.5rem;
}

.FE-export-button__wrapper .arIcon,
.FE-export-button__wrapper .exIcon {
  border: 0;
}

.FE-export-button__wrapper .arIcon:hover,
.FE-export-button__wrapper .exIcon:hover {
  color: #000;
  background-color: #fff;
  text-decoration: underline;
}

.FE-export-button__wrapper .arIcon:active,
.FE-export-button__wrapper .exIcon:active {
  color: #000;
  background-color: #ddd;
  text-decoration: normal;
}
