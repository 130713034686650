$leftPanelItemHeight: 2.4rem;

.folder {
  padding: 5px 15px 5px 10px !important;
  margin-left: 20px !important;
  height: unset !important;
  border-left: 2px solid #dedede !important;
}

.folderName {
  font-size: 14px !important;
}

.grayBottomBorder {
  border-bottom: 1px solid rgb(213, 213, 213);
}

.miniTable-ruleItem {
  font {
    font-weight: bold;
  }
}

.dg-groupsHolder {
  display: flex;
  padding-left: 0px !important;
  padding-right: 0px !important;
  justify-content: space-between;
}

.dg-container {
  padding-top: 25px;
  min-width: 250px;
  margin: 10px 10px 0 10px;
  // height: 100%;
  flex: 1 1 0%;
}

.dg-container {
  // padding: 25px 0 0 15px;
  // padding-left: 15px;
  border: 1px solid rgb(213, 213, 213);
  h3 {
    margin: 0;
    padding: 0 0 10px 0;
    font-size: 1.1rem;
  }
  div {
    padding: 0 15px 0 15px;
  }
  .dg-dialougesGroups {
    display: flex;
    flex-direction: column;
    // border-bottom: 1px solid rgb(213, 213, 213);
    // height: 140px;
    padding-bottom: 25px;

    p {
      line-height: 1.5em;
    }
  }

  .triage-overflow-section {
    overflow-y: scroll;
    height: 73vh;
    padding: 0px !important;
  }

  .triage-queue {
    // border-bottom: 1px solid rgb(213, 213, 213);
    display: flex;
    flex-direction: column;
    padding: 0 0 30px 0;

    h3 {
      padding-top: 25px;
      padding-left: 15px;
    }

    button {
      font-size: 1rem;
      height: $leftPanelItemHeight;
      border: none;
      background: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
      cursor: pointer;

      .triage-queue-tags {
        border: 1px solid rgb(213, 213, 213);
        background: white;
        font-size: 0.9em;
        border-radius: 20px;
        padding: 1px 8px;
      }
    }
    button:hover {
      background: rgb(213, 213, 213);
    }
  }

  .inactive-groups {
    display: flex;
    flex-direction: column;

    p {
      font-size: 1rem;
      line-height: $leftPanelItemHeight;
    }
  }
}
