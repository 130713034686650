.arIcon, .exIcon
{
  /*position: absolute;
  top: 0px;
  right: 11.5em;*/
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 8em;
  font-size: 0.8em;
  background-color: white;
  color: rgb(45, 45, 45);
  border: 1px solid rgb(45, 45, 45);
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  user-select: none;
  transition: background-color 250ms ease-out, color 50ms ease-out, border-color 50ms ease-out;
}
.marginRight {
  margin-right: 1rem;
}

.noIcon {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 8em;
  height: 36px;
  font-size: 0.8em;
  background-color: white;
  color: rgb(45, 45, 45);
  border: 1px solid rgb(45, 45, 45);
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  user-select: none;
  transition: background-color 250ms ease-out, color 50ms ease-out, border-color 50ms ease-out;
}

/*.exIcon
{
  width: 9.5em;
  right: 1.2em;
}*/

.arIcon:hover, .exIcon:hover
{
  background-color: rgb(45,45,45);
  color: white;
}

.arIcon:active, .exIcon:active
{
  border-color: rgb(148, 148, 148);
  color: rgb(108, 108, 108);
}

.exIcon--disabled
{
  /*color: rgb(188, 188, 188);
  border-color: rgb(188, 188, 188);*/
  opacity: 0.6;
  cursor: default;
}

.exIcon--disabled:hover, .exIcon--disabled:active
{
  background-color: white;
  color: rgb(45, 45, 45);
  border: 1px solid rgb(45, 45, 45);
  /*color: rgb(188, 188, 188);
  border-color: rgb(188, 188, 188);*/
}
