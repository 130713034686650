.removeBtn {
  display: inline-block;
  float: right;
}

.matchInfo {
  display: inline-block;
  width: 280px;
}

.mainMatch {
  border-top: 1px solid #bdbdbd;
  padding: 10px 0px !important;
}

.removeBtn {
  cursor: pointer;
  display: inline-block;
  float: right;
  userselect: none;
}

.bottomMatch {
  border-bottom: 1px solid #bdbdbd;
}

.invalid {
  font-size: 12px;
  font-weight: bold;
  transform: translate3d(0, 0, 0);
  color: red;
  position: relative;
  opacity: 0;
}

.valid {
  font-size: 12px;
  font-weight: bold;
  color: #78ab63;
  position: relative;
}

.showInvalid {
  animation: shake 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  1%,
  100% {
    opacity: 1;
  }

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-3px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(3px, 0, 0);
  }
}
