.sunnyHeaderHolder {
  background-image: url(../assets/sunny_header.png);
  width: 100%;
  height: 105px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 2;
}

.sunnyHeaderHolder--test {
  background-image: url(../assets/sunny_header_puzzle.png);
}

.fullMsg {
  width: 360px;
  display: inline-block;
  margin: 5px 0px;
}

.messages {
  padding: 10px 20px;
  overflow: scroll;
  position: absolute;
  z-index: 1;
  top: 200px;
  bottom: 70px;
  align-items: flex-end;
  width: 400px;
  display: flex;
}

.messages > :first-child {
  margin-top: auto !important;
}

.msgPadding {
  padding: 15px 20px;
}

.selfMessage {
  max-width: 90%;
  width: fit-content;
  float: right;
  border-radius: 10px 10px 0px 10px;
  background: #873450;
  color: white;
  padding: 15px 20px;
}

.selfMessageDate {
  max-width: 90%;
  width: fit-content;
  float: right;
  padding-right: 5px;
  margin-top: -12.5px;
  font-size: 0.7rem;
  color: gray;
}

.prodMsgColor {
  background: #01a2e0;
}

.otherMessage {
  max-width: 90%;
  width: fit-content;
  padding: 15px 20px;
  border: 1px solid gray;
  background: white;
  border-radius: 10px 10px 10px 0px;
}

.otherMessageDate {
  max-width: 90%;
  width: fit-content;
  padding-left: 5px;
  margin-top: -7.5px;
  font-size: 0.7rem;
  color: gray;
}

.chatbotBannerMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  width: -moz-fit-content;
  border-radius: 5px;
  background: #f2f2f2;
  color: #737373;
  padding: 15px 20px;
}

.matchHolder {
  /* width: 100%; */
}

.matchHolder .match {
  padding: 6px 20px;
  text-transform: capitalize;
  text-align: center;
  border-radius: 21px;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 4px;
  color: #4087df;
  border: 1px solid #808080;
  font-weight: bold;
  /* background: #f7c72a; */
  box-shadow: 0px 0px 2px #dedede;
  cursor: pointer;
}

/* .matchHolder .match:first-child {
  border-top: none;
} */

.svgHolder {
  height: 105px;
}

.sendMessage {
  position: absolute;
  bottom: 0;
  display: flex;
  height: 75px;
  width: 400px;
  border-top: 1px solid darkgrey;
  background: white;
  z-index: 1;
}

.runningSunny {
  background-color: white;
  width: 100%;
  border: none;
  height: 70px;
  box-shadow: none;
  background: #d0d0d0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d1d1d;
}
