.hider {
  background: black;
  opacity: 0.5;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 20;
}

.popUpholder {
  position: absolute;
  background: white;
  width: 70vw;
  top: 30px;
  left: 10%;
  z-index: 21;
}

.mainText {
  font-size: 18px;
  font-weight: bold;
  padding-left: 2px;
}

.inner_padding {
  padding: 68px;
}

.type_container {
  width: 27%;
  margin-right: 36px;
  border: 1px solid #cacaca;
  border-radius: 7px;
  position: relative;
  z-index: 8;
  overflow: hidden;
  vertical-align: top;
  margin-top: 20px;
  padding-bottom: 33px;
  cursor: pointer;
}

.type_icon {
  width: 100%;
  height: 100%;
  position: absolute;
  fill: #f2f2f2;
  color: #f2f2f2;
  display: flex;
  z-index: 7;
  align-items: center;
  justify-content: center;
}

.type_title {
  text-transform: uppercase;
  font-weight: bold;
  z-index: 8;
  position: relative;
}

.type_info {
  z-index: 8;
  position: relative;
  font-size: 17px;
  line-height: 24px;
  padding-top: 9px;
}

.type_text_holder {
  z-index: 8;
  /* position: absolute; */
  padding: 18px;
}

.type_add_btn {
  position: absolute;
  position: absolute;
  bottom: 0;
  width: 101%;
  background: #f6c423;
  font-weight: bold;
  font-size: 13px;
  text-align: center;
  padding: 8px 0px;
  border: 1px solid #f6c423;
  margin-left: -1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 8;
}

.itemsContainer {
  display: flex;
  flex-wrap: wrap;
}

.exit {
  width: 14px;
  float: right;
}

.types {
  display: flex;
}

.type {
  padding: 10px 5px;
  cursor: pointer;
}
