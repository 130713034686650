.fullCont {
  width: 400px;
  height: 100vh;
  overflow: scroll;
  position: fixed;
  right: -400px;
  top: 0;
  background: white;
  box-shadow: 0px 0px 10px #b1b1b1;
  z-index: 1000000;
  transition-delay: 1s;
  transition: all 0.3s ease-in-out
}

.editHeader {
  padding: 20px;
  text-align: center;
  position: fixed;
    width: 400px;
  z-index: 15;
  background: white;
}

.offSetDiv {
  padding-top: 58px;
}

.borderBottom {
  border-bottom: 1px solid #d6d6d6;
}

.text {
  font-size: 16px;
  font-weight: 500;
}

.exit {
  position: absolute;
  right: 19px;
  top: 20px;
  width: 13px;
  color: #393b3f;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.exit:hover {
  color: #000;
}

.typeText {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
}

.typeCont {
  background: #f4f4f4;
    padding: 30px 20px;
}

.typeInfo {
  color: #464646;
  font-size: 16px;
  line-height: 23px;
  padding-top: 14px;
}

.nodeIdCont {
  color: #272727;
  font-size: 14px;
  padding: 20px;
  font-weight: 500;
  position: relative;
}

.buttonCont {
  position: fixed;
  bottom: 0;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 15px;
  width: 400px;
  border-top: 1px solid #d6d6d6;
  background: white;
  transition: all 0.3s ease-in-out;
  margin-left: -20px;
}

.editBtnBase {
  border-radius: 5px;
  /* padding: 4px 23px; */
  cursor: pointer;
  width:100%;
  height: 100%;
}

.editDeleteBtn {
  background-color: white;
  color: #313131;
  padding-left:0px;
  padding-right:0px;
}

.editSaveBtn {
  float: right;
  background: #8b1e3f;
  color: white;
}

.inline {
  display: inline-block;
  vertical-align: top;
}

.nodeIdText {
  width: 20%;
}

.nodeId {
  overflow-wrap: break-word;
  width: 70%;
}

.nodeTextArea {
  resize: none;
  border: none;
  font-size: 14px;
  box-shadow: none;
  outline: none;
      padding: 0px 0px;
}

.copyBtn {
  height: 15px;
  color: gray;
  fill: #6d6d6d;
  vertical-align: text-bottom;
  cursor: pointer;
}

.copiedOverlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: #006fffc2;
  color: white;
  margin-left: -20px;
  font-size: 16px;
  font-weight: bold;
  opacity: 0;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  z-index: -5;
}

.showCopied {
  animation: show 1.5s forwards;
}

.flexOptions {
  display: flex;
  justify-content: space-between;
}

.copiedText {
  margin-top: 28px;
  user-select: none;
}

.botEnvText {
  text-align: center;
  width: 50%;
  font-size: 15px;
  cursor: pointer;
}

.underline {
  height: 3px;
  width: 50%;
  position: absolute;
  bottom: 0;
  left: 0%;
  transition: left 0.3s ease-in-out;
  background: #873450;
}

@keyframes show {
  0% {
    opacity: 0;
    z-index: 1;
  }
  25% {
    opacity: 1;
    z-index: 1;
  }
  75% {
    opacity: 1;
    z-index: 1;
  }
  99% {
    opacity: 0;
    z-index: 1;
  }
  100% {
    z-index: -5;
  }
}
