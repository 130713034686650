.hoverCategory:hover {
  background-color: #f4f4f4;
  cursor: pointer;
}

.hoverQuestion:hover {
  /* font-weight: bold; */
  margin-left: -1rem;
  margin-right: -1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #f4f4f4;
  cursor: pointer;
}

.hoverButton:hover {
  font-weight: 900;
  cursor: pointer;
}

.hoverDivider:hover {
  cursor: default;
}

.CreateUser {
  border: 1px solid white;
  background-color: gold;
  color: black;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
}

.ExportButton {
  padding-top: 0.18rem;
  padding-right: 1rem;
}

.SectionHeader {
  margin-top: 1.1rem;
  margin-left: 0.86rem;
  padding-right: 1rem;
}

.TablePage {
  margin-top: -1.6rem;
  margin-right: -2.6rem;
  margin-left: -1.6rem;
  border: 1px solid white;
}
