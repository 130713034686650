.paddedSection {
  padding: 0px 20px 0px 20px;
  padding-bottom: 80px;
  overflow-y: scroll;
  // height: calc(100vh - 70px);
}

.paddedSection--viewOnly .buttonCont {
  display: none;
}
