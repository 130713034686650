.tableLabel__rectangle,
.tableLabel__title {
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.tableLabel__total {
  padding-left: 1.7rem;
  padding-bottom: 2rem;
}

.tableLabel__body {
  font-weight: normal;
  align-items: flex-start;
  float: left;
  color: grey;
}
