p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: black;
}

.mainStatusBar {
  width: 100vw;
  z-index: 1000000;
  padding: 10px;
  color: white;
  font-weight: bold;
  position: fixed;
  top: 64px;
  overflow: hidden;
  cursor: pointer;
  max-height: 37px;
  transition: max-height 0.3s ease-out;
}

.mainStatusBarOpen {
  transition: max-height 0.3s ease-in;
  max-height: 600px;
}

.warning {
  background: orange;
}

.info {
  background: #0098df;
}

.critical {
  background: #e42a00;
}

.statusDesc {
  margin-top: 10px;
  font-weight: normal;
}

body {
  /* overflow: hidden; */
  // overscroll-behavior: none;
  // -ms-scroll-chaining: chained;
  // -ms-overflow-style: none;
  // -ms-content-zooming: zoom;
  // -ms-scroll-rails: none;
  // -ms-content-zoom-limit-min: 100%;
  // -ms-content-zoom-limit-max: 500%;
  // -ms-scroll-snap-type: proximity;
  // -ms-scroll-snap-points-x: snapList(100%, 200%, 300%, 400%, 500%);
  // -ms-overflow-style: none;
  // width: 480px;
  // height: 270px;
  overflow: auto;
}

.App {
  /* background-color: #f4f4f4; */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.Header-container {
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 2px solid var(--border-color-grey);
  height: 4em;
  position: fixed;
  width: 100vw;
  background: white;
  z-index: 10;
  top: 0;
}

.Header-left-box {
  display: flex;
  width: 16em;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  border-right: 2px solid var(--border-color-grey);
  padding: 0.5em;
}

.Header-right-box {
  display: flex;
  width: 74%;
  align-items: center;
  flex-flow: row nowrap;
  margin: 0% 3%;
}

.App-row-container {
  display: flex;
  flex-flow: row nowrap;
}

.Nav-bar-container {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 20%;
  height: calc(100vh - 4em);
  text-align: center;
  position: fixed;
  background-color: white;
  border: 2px solid var(--border-color-grey);
  border-top: 0px solid black;
  // margin-top: 4em;
  // overflow-y: scroll;
}

.Nav-bar-box {
}

.Nav-bar-item {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  text-decoration: none;
  padding: 1.5em 0;
}

.Nav-bar-item-left {
  width: 25%;
  color: grey;
}

.Nav-bar-item-right {
  width: 75%;
  text-align: left;
}

.Nav-bar-login-container {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: space-around;
  background-color: rgb(42, 47, 61);
  padding: 2em 1em;
  text-align: left;
  position: absolute;
  bottom: 0;
  width: 239px;
}

.Nav-bar-login-row {
  display: flex;
  flex-flow: row wrap;
}

.Nav-bar-login-text {
  color: white;
  font-size: 15px;
  padding: 0.3em 0.1em;
}

.Main-container {
  margin-top: 4.5em;
}

.positionInner {
  height: calc(100% - 4em);
}

.Main-inner-container {
  padding: 1em 1em;
}

.test-components {
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.clickable {
  cursor: pointer;
}

.divider_MainApp,
.BreadCrumbs {
  display: flex;
  vertical-align: top;
}

/*@media  (-webkit-max-device-pixel-ratio: 1) {
  .Main-container {
    margin-top: 91px!important;
  }
  */
