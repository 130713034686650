$leftPanelItemHeight: 2.4rem;

.online {
  background: #63cc22;
}

.offline {
  background: transparent;
  border: 1px solid grey;
}

.selectedConvo {
  // z-index: 999;
  // clip-path: polygon(100% 0, 100% 35%, 120% 50%, 100% 71%, 100% 100%, 0 100%, 0 0);
  border: 2px solid gold;
}

.subMenuParentTitle {
  // padding-right: 40px;
}

.resolveBtn {
  border: 1px solid gray;
  padding: 1px 10px;
  border-radius: 4px;
}

.nonSelectedConvo {
  border-top: none;
  border-bottom: 1px solid #d5d5d5;
  border-left: 1px solid #d5d5d5;
}

.subMenuParent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.baseDot {
  height: 15px;
  width: 15px;
  // position: absolute;
  // z-index: 5;
  border-radius: 10px;
  bottom: 15px;
}

.ring-container {
  position: relative;
}

.circle {
  width: 15px;
  height: 15px;
  background-color: #62bd19;
  border-radius: 50%;
  position: absolute;
  top: 23px;
  left: 23px;
}

.ringring {
  border: 3px solid #62bd19;
  -webkit-border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  left: 15px;
  top: 15px;
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
}
.main-container {
  // margin-right: 0.5rem; //remove this when sidebar in
  display: flex;
  min-width: 1159px;
}

.simpleEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.main-dialogue-container {
  margin: 10px 10px 0 0;
  width: 100%;
}

.dialouge-title-container {
  border: 1px solid rgb(213, 213, 213);
  height: 75px;
  display: flex;
  align-items: center;
}

.search-container {
  width: 100%;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.23);
  height: 4em;
  display: flex;
  align-items: center;
  //justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;

  input {
    border: 0;
    padding-left: 10px;
  }
  select {
    border: 0;
  }
}

.sort-div {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 0.8rem;
}

.triage-message-selector {
  border: 1px solid rgb(213, 213, 213);
  height: 70px;
  /*display: flex;
  align-items: center;*/
  // justify-content: space-around;
  font-size: 0.9em;
  white-space: pre;

  .triage-buttons-containers {
    width: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    position: relative;
  }

  .triage-message-button {
    border: none;
    height: 2.5em;
    background: none;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    text-transform: none;
    line-height: 1.2;

    p {
      font-weight: 800;
    }

    .triage-message-tags {
      margin-left: 5px;
      background: gold;
      font-size: 0.9em;
      border-radius: 20px;
      padding: 1px 8px;
    }
  }

  .triage-buttons-containers-wrapper {
    /*padding-right: 2.5rem;*/

    display: flex;
    align-items: center;
    justify-content: space-around;

    position: relative;

    width: 100%;
    height: 100%;
  }

  .openDialogue {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 20px 0 0 20px;
    background-color: #404040;
    color: white;
    display: flex;
    justify-content: center;

    /*position: absolute;
    top: 0px;
    right: 0px;*/
  }

  .openDialogue:focus {
    outline: none;
  }

  .dialogue-properties-header {
    margin: 0;
    //width: 50%;
    height: 100%;
    background-color: #404040;
    /*flex: 1 1 0%;*/
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    //transition: all 0.6s ease;
    vertical-align: top;
    /*min-width: 448px;*/

    h3 {
      font-size: 1.1rem;
      margin-left: 20px;
    }

    div {
      font-size: 0.8rem;
      background-color: #6da0f2;
      padding: 6px 10px;
      border-radius: 25px;
      margin-right: 10px;
      font-weight: 800;
    }
  }

  button:hover {
    cursor: pointer;
  }
}

.selector-buttons {
  // border: 1px solid black;
  width: 55%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-left: 10%;

  button {
    border: none;
    background: none;
    font-size: 1.2em;
    font-weight: 900;
    white-space: nowrap;

    p {
      // border: 1px solid gold;
      background: gold;
      border-radius: 10px;
      padding: 0 5px;
      font-weight: 200;
      font-size: 0.9em;
    }
    // margin: 0 5%;
  }
}

.dialogues-opportunity-page {
  padding: 0;
}

.dialogues-opportunity-collapsable {
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 3rem;
  overflow: hidden;
  /*line-height: 3;*/
  position: relative;
  margin-top: 1.2em;
}

.dialogues-opportunity-collapsable--open {
  height: auto;
}

.opportunity-collapsable-row {
  display: block;
  margin-bottom: 0.5rem;
  padding-left: 1em;
}

.opportunity-collapsable-name {
  display: inline-block;
  font-weight: bold;
}

.opportunity-collapsable-content {
  display: inline-block;
}

.opportunity-collapsable-arrow {
  position: absolute;
  top: 0.75rem;
  right: 0.6rem;
  cursor: pointer;
}

.dialogues-opportunity-collapsable--open .opportunity-collapsable-arrow {
  transform: rotate(180deg);
}

.filterActiveButton {
  background: gold;
  border-radius: 25px;
  font-weight: bold;
  max-width: max-content;
  padding: 0.5em 0.5em;
  display: inline-block;
  margin-left: 0.5em;
  max-height: max-content;
  border: solid 2px white;
  cursor: pointer;
}

.search-filter-row {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.search-filter-row > div {
  padding-left: 0.7em;
}

.filter-triage-queue-wrapper {
  margin-left: 1em;
 /* position: relative;*/
}

.filter-triage-queue-button {
  border: 1px solid rgb(201, 201, 201);
  border-radius: 5px;
  padding: 0.5em;
  padding-left: 1em;
  font-weight: 400;
  font-size: 16px;
  width: 30em;
  cursor: pointer;
  position: relative;
  user-select: none;
  height: 2.475em;
  transition: opacity 250ms ease-in-out;
}

.filter-triage-queue-button::before {
  content: '';
  position: absolute;
  right: 1em;
  top: 0.875em;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgb(151, 151, 151);
  clear: both;
  border-radius: 5px;
}

.filter-triage-queue-button::after {
  content: '';
  position: absolute;
  right: 1em;
  top: 0.8em;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgb(255, 255, 255);
  clear: both;
  border-radius: 5px;
}

.filter-triage-queue-button--gray {
  opacity: 0.7;
  background-color: rgb(240, 240, 240);
  cursor: default;
}

.filter-triage-queue-button--gray::after {
  border-top-color:rgb(240, 240, 240);
}

.filter-triage-queue-panel {
  background: white;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  padding: 0.5em;
  font-weight: 400;
  font-size: 16px;
  width: 30em;
  position: absolute;
  top: 1.5875em;
  z-index: 1060;
  height: 25em;
  overflow: hidden;
  animation: openTriageQueuePanel forwards ease-out 300ms;
}

.filter-triage-queue-panel__close-button {
  position: absolute;
  top: 0.3em;
  right: 0;
  width: 2.5em;
  height: 2em;
  cursor: pointer;
  z-index: 50;
}

.filter-triage-queue-panel__close-button::before {
  content: '';
  position: absolute;
  right: 1em;
  top: 0.8em;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid rgb(151, 151, 151);
  clear: both;
  border-radius: 5px;
}

.filter-triage-queue-panel__close-button::after{
  content: '';
  position: absolute;
  right: 1em;
  top: 0.875em;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid rgb(255, 255, 255);
  clear: both;
  border-radius: 5px;
}

.filter-triage-queue-panel__row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
  position: relative;
}

.filter-triage-queue-panel__row[data-align="left"] {
  justify-content: flex-start;
}

.filter-triage-queue-panel__row[data-align="space"] {
  justify-content: space-between;
}

.filter-triage-queue-panel__text {
  width: max-content;
  margin-top: 0.4em;
}

.filter-triage-queue-panel__bold-text {
  width: 50%;
  font-weight: 700;
  font-size: 14px;
}

.filter-triage-queue-panel__gray-button {
  position: relative;
  border-radius: 50px;
  background: rgb(232, 232, 232);
  padding: 0.3em 1em;
  padding-right: 2em;
  margin-left: 0.5em;
  font-size: 14px;
  cursor: pointer;
}

.filter-triage-queue-panel__gray-button[data-search=""] {
  display: none;
}

.filter-triage-queue-panel__gray-close-button {
  position: absolute;
  right: 0.9em;
  top: 0.4em;
  font-size: 13px;
  color: rgb(100, 100, 100);
}

.filter-triage-queue-panel__input {
  width: 100%;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(184, 184, 184);
  outline: #000;
  font-size: 14px;
  padding: 0.5em 0.5em;
}

.filter-triage-queue-panel__input--date {
  border-radius: 0;
  width: 11em;
  text-align: center;
}

.filter-triage-queue-panel__input-close-button {
  position: absolute;
  right: 0.9em;
  top: 0.42em;
  font-size: 12px;
  background-color: rgb(239, 239, 239);
  border-radius: 50%;
  padding: 0.3em 0.6em;
  padding-bottom: 0.1em;
  color: rgb(100, 100, 100);
  cursor: pointer;
}

.filter-triage-queue-panel__input-close-button[data-state=""] {
  display: none;
}

.filter-triage-queue-panel__link {
  color: maroon;
  text-decoration: underline;
  cursor: pointer;
}

.filter-triage-queue-panel__apply {
  font-size: 14px;
  background-color: gold;
  border-radius: 50px;
  padding: 0.3em 0.6em;
  padding-bottom: 0.1em;
  color: #000;
  cursor: pointer;
  outline: 0;
  border: 0;
  font-weight: 700;
  padding: 0.5em 3em;
}

.filter-triage-queue-panel__apply:active {
  background-color: rgb(255, 227, 74);
}

.filter-triage-queue-panel__campaignList {
  border-top: 2px solid rgb(215, 215, 215);
  height: 12em;
  overflow-y: scroll;
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.filter-triage-queue-panel__list-item {
  height: 3em;
  padding-top: 0.5em;
  padding-bottom: 3.3em;
  padding-left: 0.5em;
  cursor: pointer;
}

.filter-triage-queue-panel__list-item:hover, .filter-triage-queue-panel__list-item[data-selected="true"] {
  background: rgb(233, 233, 233);
}

.filter-triage-queue-panel__list-item-title {
  font-weight: 700;
}

.filter-triage-queue-panel__list-item-desc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  width: 100%;
}

@keyframes openTriageQueuePanel {
  0% {
    height: 2.475em;
  }
  100% {
    height: 30em;
  }
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
