.varsSection {
  flex-wrap: wrap;
  display: flex;
  max-height: 200px;
  overflow: scroll;
}


.varsTitle {
  margin: 1rem 0px;
  font-weight: bold;
  font-size: 0.875rem;
  position: relative;
}


.variablesHolder {
  width: 320px;
  padding: 10px;
  border: 1px solid #cecece;
  border-radius: 5px;
}


.searchIcon {
  position: absolute;
  right: 7px;
  top: 8px;
  color: gray;
  cursor: pointer;
}
