.AlertBanner {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgb(45, 45, 45);
  height: 58px;
  padding: 0px;
  margin: 0px;
  width: 100%;
  z-index: 1500;

  display: none;

  color: white;
  font-size: 1.2em;
}

.AlertBanner--userWaiting {
  display: flex;
  align-items: center;
  justify-content: center;
}

.AlertBanner__messageWrapper {
  margin-right: 0.5em;
}

.AlertBanner__messageContent {
  display: inline-block;
  margin-right: 0.3em;
}

.AlertBanner__messageContent--bold {
  font-weight: bold;
}

.AlertBanner__button {
  border: 1px solid white;
  border-radius: 6px;
  padding: 0.1em;
  padding-left: 1em;
  padding-right: 1em;
  margin-right: 0.3em;

  min-width: 124px;
  text-align: center;
  cursor: pointer;

  transition: background-color 250ms ease-out, color 250ms ease-out,
    border-color 250ms ease-out;
}

.AlertBanner__button:hover {
  /*background-color: ;*/
  color: #f6bf03;
  border-color: #f6bf03;
}

.AlertBanner__button--inverted {
  background-color: white;
  color: rgb(0, 0, 0);
}

.AlertBanner__button--inverted:hover {
  background-color: #f6bf03;
  border-color: #f6bf03;
  color: rgb(0, 0, 0);
}
