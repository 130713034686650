.AccordionSummary--expanded {
  width: 100%;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
}

.summaryTitle {
  font-size: 1.15em !important;
  font-weight: 800 !important;
  margin: 0;
}

.summaryContent--question {
  font-size: 0.9em;
  font-weight: 400;
  white-space: normal;
}

.summaryContent--type {
  font-size: 0.9em;
  font-weight: 400;
}

.detailsContent {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.detailsContent--max-width {
  width: 100%;
}

.includeFlowSwitch .flowComponent {
  display: block;
}

.includeFlowSwitch textarea {
  background-color: rgb(231, 231, 231);
}

.excludeFlowSwitch .flowComponent {
  display: none;
}

.excludeFlowSwitch textarea {
  background-color: #fff;
}

.matched-count {
  flex-grow: 1;
  font-size: 1.15em;
  font-weight: 400;
  margin-left: 1em;
}
