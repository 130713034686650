.showBot {
  position: fixed;
  z-index: 5;
  top: 160px;
  right: -30px;
  height: 70px;
  width: 70px;
  background-image: url(assets/sunnysmile70.png);
  background-size: cover;
  background-position: center;
  background-repeat: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.showBot:hover {
  background-image: url(assets/sunnysmile70.png);
  right: -10px;
}
