.threeSquareWrapper
{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  width: 45px;
  margin-top: 15px;
  margin-left: 0px;
}

.threeSquare
{
  width: 10px;
  height: 10px;
  background-color: rgb(45, 45, 45);
}

.threeSquare-1
{
  animation: threeSquareFadeIn infinite ease-in-out 1250ms 0ms;
}

.threeSquare-2
{
  animation: threeSquareFadeIn infinite ease-in-out 1250ms 100ms;
}

.threeSquare-3
{
  animation: threeSquareFadeIn infinite ease-in-out 1250ms 200ms;
}

@keyframes threeSquareFadeIn
{
  0%
  {
    opacity: 0.3;
  }
  50%
  {
    opacity: 1;
  }
  100%
  {
    opacity: 0.3;
  }
}
