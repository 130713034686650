@import url("https://unpkg.com/treeflex@2.0.1/dist/css/treeflex.css");

body {
  /* overscroll-behavior: none;
  overflow: hidden; */
}

.Main-container {
  // overflow-y: hidden;
  // height: calc(100% - 4em);
}

.holder {
  background-color: #f4f4f4;
  width: 100%;
  height: 100%;
}

.card {
  width: 200px;
  height: 100px;
  border: 1px solid black;
}

.viewOnlyMode {
  position: absolute;
  bottom: 6px;
  width: 100%;
  color: white;
  padding: 20px;
  background: #de7a13;
  text-align: center;
  opacity: 0.9;
  font-size: 19px;
  transition: 0.3s all ease-in-out;
}

.viewOnlyHide {
  bottom: 6px;
  height: 0px;
  overflow: hidden;
  padding: 0px;
}

.scrollHolder {
  height: calc(100vh - 4em);
  // width: 100vw;
}

.PanHolder {
  text-align: center;
  position: relative;
  z-index: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: #f4f4f4;
}

.Buttons {
  position: absolute;
  right: 0;
  z-index: 11;
  padding: 25px;
  transition: all 0.3s ease-in-out;
  display: inline-block;
}

.zoomOffset {
  top: 20px;
}

.zoomOffset1 {
  top: 40px;
}

.zoomCont {
  background: white;
  border: 1px solid #7d7d7d;
  border-radius: 3px;
  text-align: center;
}

.zoomBtnBorder {
  border-bottom: 1px solid #7d7d7d;
}

.zoomBtn {
  font-size: 22px;
  user-select: none;
  cursor: pointer;
  color: #3e3e3e;
}

.zoomUnavail {
  color: white;
  background: #d2d2d2;
  pointer-events: none;
}

.buttonOptn {
  margin-left: 15px;
  cursor: pointer;
  vertical-align: text-top;
  display: inline-block;
  /* box-shadow: 0px 0px 5px #d8d8d8; */
}

.btnTextHolder {
  display: inline-block;
}

.basicButton {
  color: #848484;
  font-size: 13px;
  max-width: 110px;
  display: inline-block;
  vertical-align: top;
  transition: color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.alterChartBtn {
  height: 11px;
  width: 11px;
  text-align: center;
  vertical-align: middle;
  fill: #848484;
}

.invalidBtn {
  color: #d8d8d8;
  pointer-events: none;
  cursor: default;
}

.iconHolder {
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
}

.textHolder {
  display: inline-block;
  max-width: 93px;
}
