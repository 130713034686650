.buttonStyle {
  background: white;
  box-shadow: none;
  border: 1px solid gray;
  border-radius: 14px;
  padding: 5px 15px;
  cursor: pointer;
}

button:focus {
  outline: none;
}

.buttonHolder {
  display: flex;
  flex-wrap: wrap;
}

.buttonItem {
  margin-right: 5px;
  margin-bottom: 5px;
}
