.SectionHeader_FAQs {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 4em;
  z-index: 2;
  position: relative;
  width: 100%;
}

.TablePage_FAQs {
  margin-left: -1rem;
  border: 0px solid white;
}
