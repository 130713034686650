.sendingMsgContV1 {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.sendingMsgV1 {
  background: #3e88df;
  padding: 1px 15px;
  color: white;
  width: fit-content;
  border-radius: 55px;
}

.sendingMsgContV1 {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.removeAttachment {
  position: absolute;
  right: 0;
  background: white;
  color: black;
  opacity: 0.85;
  border-radius: 50px;
  cursor: pointer;
}

.filePreviewAtachment {
  font-size: 16px;
  color: royalblue;
  padding: 5px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin-left: 10px;
}

.fileAttachmentInMsg {
  display: flex;
  font-size: 12px;
  text-decoration: underline;
  color: royalblue;
  cursor: pointer;
}

.sendingMsgV1 {
  background: #3e88df;
  padding: 1px 15px;
  color: white;
  width: fit-content;
  border-radius: 55px;
}

.notSent {
  opacity: 0.5;
}

.msgDiv {
  width: 360px;
  // display: inline-block;
  display: flex;
  align-items: flex-end;
  margin: 5px 0px;
  // background-color: white;
  // transition: background-color 0.7s linear;
}

.flashMsg {
  background-color: #cacaca;
}

@keyframes bgColorChange {
  0% {
    background-color: white;
  }
  50% {
    background-color: #cacaca;
  }
  100% {
    background-color: white;
  }
}

.changeBgColor {
  -webkit-animation: bgColorChange 0.3s linear infinite alternate;
  -moz-animation: bgColorChange 0.3s linear infinite alternate;
  -ms-animation: bgColorChange 0.3s linear infinite alternate;
  -o-animation: bgColorChange 0.3s linear infinite alternate;
  animation: bgColorChange 0.3s linear infinite alternate;
}

.selfDialogueMessage {
  max-width: 90%;
  width: fit-content;
  padding: 10px;
  border: 1px solid gray;
  background: white;
  border-radius: 20px 20px 20px 0px;
}

.agent {
  border: 1px solid #3f88df;
  background: #d7e8ff;
}

.selfDialogueMessageDate {
  max-width: 90%;
  width: fit-content;
  padding-left: 40px;
  margin-top: -2px;
  font-size: 0.7rem;
  color: gray;
  display: flex;
  align-items: center;
  z-index: -1;
}

.otherDialogueMessage {
  max-width: 90%;
  width: fit-content;
  float: right;
  border-radius: 20px 20px 0px 20px;
  background: #873450;
  color: white;
  padding: 10px;
}

.otherDialogueMessageDate {
  max-width: 90%;
  width: fit-content;
  float: right;
  padding-right: 50px;
  margin-top: -2px;
  font-size: 0.72rem;
  color: black;
  display: flex;
  align-items: center;
}

.sunnyIcon {
  height: 30px;
  width: 30px;
  // background-image: url(assets/sunnysmile.png);
  background-image: url(../../components/Sunny/assets/sunnysmile50.png);
  background-size: cover;
  background-position: center;
  background-repeat: none;
}

.dashboardIcon {
  height: 30px;
  width: 30px;
  background-color: rgb(62, 136, 223);
  font-size: 1.2em;
  font-weight: normal;
  color: white;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  margin-right: 0.3em;
}

.char-count-icon-wrapper {
  color: rgb(150, 150, 150);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  transition: opacity 250ms ease-out;
}

.char-count-icon-wrapper:hover {
  opacity: 0.7;
}

.char-count-banner {
  position: relative;
  height: 2.7em;
  background-color: rgb(62, 136, 223);
  line-height: 2.7em;
  color: white;
  padding-left: 50px;
  padding-right: 50px;
}

.char-count-banner__close-button {
  position: absolute;
  right: 30px;
  top: 0px;
  text-align: right;
  height: 100%;
  line-height: 2.7em;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 250ms ease-out;
  padding-left: 20px;
  padding-right: 20px;
}

.char-count-banner__close-button:hover {
  background-color: #5094e2;
}

.chatbotPausedButtonWrapper {
  position: absolute;
  top: -3.7em;
  left: 0;
  width: 100% !important;
  width: -moz-available !important;
  width: -webkit-fill-available !important;
}

.chatbotPausedButton {
  color: white;
  width: max-content;
  margin: 0 auto;
  text-align: center;
  padding: 0.4rem 1.6rem;
  font-size: 0.875rem;
  background: #3f88df;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  line-height: 1.75;
  border-style: none;
  border-width: 0px;
  letter-spacing: 0.02857em;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.chatbotPausedButton:hover {
  background-color: #7caee9;
}

.message-review {
  opacity: 1;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    opacity 250ms ease-out !important;
}

.message-review-pending {
  opacity: 0.3;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    opacity 250ms ease-out !important;
}
