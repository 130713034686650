.announcementPaperStyle {
  width: 95%;
  margin: 5rem auto;
  border-radius: 0px;
  margin-bottom: 1em;
}

.announcementTopPartHolder {
  padding-left: 3rem;
  padding-top: 0.5em;
  padding-bottom: 1.25em;
}
.announcementLabelHolder {
  font-weight: bold;
  font-size: 14px;
  margin-top: 1em;
  display: inline-block;
}
.announcementActiveLabel {
  margin-top: 1em;
  display: inline-block;
  margin-left: 3em;
}

.announcementPaperStyle2 {
  width: 95%;
  margin: 5rem auto;
  border-radius: 0px;
  margin-top: 0;
  min-height: 50%;
}
