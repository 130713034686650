.sectionLabel {
  font-size: 13px;
  font-weight: bolder;
}

.sectionText {
  font-size: 15px;
  color: #5f5f5f;
}

.section {
  padding-bottom: 20px;
  padding-top: 5px;
}

.section div {
  padding-bottom: 5px;
}

.errorPopup {
  position: fixed;
  z-index: 100000;
  background: #ff004b;
  bottom: -150px;
  width: 400px;
  right: 0;
  padding: 25px;
  font-size: 16px;
  color: white;
  transition: bottom 1s;
}
