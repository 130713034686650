.SectionHeader-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: "90%";
  height: 4em;
  padding: 1% 2%;
  background-color: white;
  border: 1px solid var(--border-color-grey);
  border-left: 0px solid var(--border-color-grey);
  border-top: 0px solid var(--border-color-grey);
  z-index: 3;
    position: absolute;
    width: 100%;
}

.SectionHeader-box {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
}

.SectionHeader-title-text {
  font-size: 20px;
  font-weight: bold;
  margin: 0px;
}

.headerHover:hover, .collapseHover:hover {
  cursor: pointer;
}
