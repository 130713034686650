.rightSideTabs {
  flex: 0 0 50%;
  border-left: 1px solid #d5d5d5;
}

.titleTab__container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(45, 45, 45);
  height: 3rem;
}

.titleTab {
  line-height: 1;
  text-transform: uppercase;
  font-weight: 500;
  transition: color 250ms linear;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.titleTab:hover {
  color: rgb(255, 198, 39) !important;
}

.titleTab__text {
  text-align: center;
  user-select: none;
}

.subTab__container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  height: 3rem;
  position: relative;
  border-bottom: 1px solid #d5d5d5;
  overflow-x: hidden;
}

.subTab {
  line-height: 1;
  text-transform: uppercase;
  font-weight: 500;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
  width: 100%;
  cursor: pointer;
  color: rgb(0, 0, 0);

  display: flex;
  justify-content: center;
  align-items: center;
}

.subTab:hover {
  background-color: rgb(240, 240, 240);
}

.subTab:active {
  background-color: rgb(200, 200, 200);
}

.subTab__text {
  text-align: center;
  user-select: none;
}

.reactiveGoldBar {
  position: absolute;
  bottom: 0px;
  left: 0px;
  transform: translateX(0px);
  width: 22%;
  height: 6px;
  background-color: rgb(255, 198, 39);
  transition: transform 200ms ease-out, width 250ms ease-out;
  pointer-events: none;
}

.panelSection {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 30px;
}

.panelSection__title {
  font-weight: bold;
  font-size: 1.15em;
  position: relative;
}

.campaignAnalyticsOverview {
  font-weight: normal;
  margin-top: 10px;
  font-size: 1em;
  width: 14em;

  /*display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: flex-start;*/

  display: flex;
  flex-wrap: wrap;
}

/*.overviewRowItem
{
  width: 100%;
  flex: 0 50%;
}*/

.CAO-Item--Large {
  flex: 3 !important;
  font-size: 0.9em;
}

.CAO-Row {
  width: 15em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.CAO-Row--Large {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.CAO-Item {
  flex: 1;
  font-size: 0.9em;
}

.CAO-Item--right {
  text-align: right;
}

.overview-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.overview-row-item {
  flex: 0.2;
  font-size: 0.9em;
}

.overview-row-item--right {
  text-align: right;
}

.importSummaryFile {
  width: 80% !important;
}

.panelHeader {
  font-weight: normal;
  font-size: 0.9em;
  margin-bottom: 40px;
  margin-top: 5px;
}

.campaignAnalyticsTable {
  margin-left: 0.2em;
  margin-right: 1.2em;
}

.campaignAnalyticsTable-header__wrapper {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-content: stretch;
  align-items: flex-start;

  border-bottom: 1px solid lightgray;
  padding-bottom: 5px;
}

.campaignAnalyticsTable-header {
  font-weight: bold;
  font-size: 0.8em;
  text-transform: uppercase;

  width: 8em;
  text-align: left;
}

.campaignAnalyticsTable-header--right {
  text-align: right;

  flex-grow: 0.5;
}

.campaignAnalyticsTable-row__wrapper {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-content: stretch;
  align-items: stretch;

  border-bottom: 1px solid lightgray;

  position: relative;
}

.campaignAnalyticsTable-item {
  font-weight: normal;
  font-size: 0.9em;

  width: 7.1em;
  text-align: left;

  padding-top: 0.7em;
  padding-bottom: 0.7em;
}

.campaignAnalyticsTable-item--maroon {
  color: rgb(143, 34, 68);
  text-align: right;
  cursor: pointer;
}

.errorCodeToolTip {
  position: absolute;
  top: 0px;
  right: 0px;
  height: max-content;
  font-size: 0.9em;
  background-color: rgb(45, 45, 45);
  /*transition: opacity 250ms ease-in-out;*/
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  /*transform: translateX(100%);*/
  pointer-events: none;
  font-weight: normal;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  opacity: 0;
  z-index: 20;
}

.campaignAnalyticsTable-item--large {
  text-align: right;
  cursor: pointer;
  flex-grow: 0.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.campaignAnalyticsTable-item--large:hover + .errorCodeToolTip {
  opacity: 1;
  /*transform: translateX(0%);*/
}

.campaignAnalyticsTableButtons {
  width: 100%;
  margin: 2.5em auto;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-content: stretch;
  align-items: center;
}

.CAT-Button {
  width: max-content;
  text-transform: uppercase;
  border: 0;
  outline: 0;
  background-color: rgb(62, 136, 223);
  color: white;
  font-weight: 500;
  border-radius: 50px;
  text-align: center;
  font-size: 0.8em;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  padding-bottom: 3px;

  cursor: pointer;
  user-select: none;
  transition: background-color 50ms ease-out, opacity 250ms ease-out;
}

.CAT-Button:hover {
  background-color: rgb(94, 152, 218);
}

.CAT-Button:active {
  background-color: rgb(46, 74, 105);
}

.CAT-Button--deactivated {
  opacity: 0.5;
  cursor: default;
}

.CAT-Button--deactivated:hover {
  opacity: 0.5;
  background-color: rgb(62, 136, 223);
}

.CAT-Button--deactivated:active {
  opacity: 0.5;
  background-color: rgb(62, 136, 223);
}

.CAT-Pages {
  width: 10vw;
  font-weight: 500;
  text-align: center;
  font-size: 0.7em;
}

.CAT-Blank {
  border-bottom-color: white;
}

.CAT-Blank > div {
  color: white;
  user-select: none;
}

.campaignAnalyticsResponses {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 0.9em;
}
